








































































































































































import Vue from "vue";

export default Vue.extend({
  name: "WeddingParty",
  data: () => ({
    questions: [
      "How did you meet Morgan and Michael?",
      "What is your favorite memory with Morgan and Michael?",
      "What do you love most about Morgan and Michael?",
    ],
    partyMembers: {
      bridesmaids: [
        {
          name: "Emily Besser",
          image: "emily",
          answers: [
            "I met Morgan through my husband. I then immediately decided to steal her away from him and I won.",
            "The proposal! I knew Morgan thought it might be happening and as we were wandering around the zoo, I started getting nervous for her because we weren't at the exact location Mike wanted to propose. When we did get there, she was probably a little confused as to why I absolutely insisted she not have her mask but who wants a mask around their neck when someone is proposing to them?!?",
            "Morgan keeps Mike in line and Mike (usually) can keep Morgan calm. They complement each other that way. And they're amazing friends-- you know you can always count on them to be there for you.",
          ],
          title: "Matron of Honor",
        },
        {
          name: "Michalia Agins",
          image: "michalia",
          answers: [
            'I met Morgan in beauty school - thank goodness! We instantly clicked and she quickly became my rock throughout that stressful year at Pivot Point. I met Mike later that year and kept thinking, "why aren\'t they together?"',
            "My favorite memories are always the hours I spend talking to them after I come over to do Morgan's hair. We talk about everything, from life updates, to serious subjects, to all things cats!",
            "I love and admire their friendship and overall partnership. They are each other's biggest supporters and #1 fans. They are also two of the most authentic people I've ever met. They are unapologetically themselves no matter what.",
          ],
          title: "Flower Girl",
        },
        {
          name: "Heather Nielsen",
          image: "heather",
          answers: [
            "I met Mike in high school. He introduced me to Moe.",
            "I’ll never forget the first time I saw them holding hands. They were quiet and trying to hide it but I saw. It was such a sweet and innocent moment.",
            "I love that they take care of each other. They love and support each other through all that life has thrown their way. Their love for one another is so evident just in the way each one cares about the other.",
          ],
        },
        {
          name: "McKenna Schaller",
          image: "mckenna",
          answers: [
            "The first time I met Mike and Morgan, I was in an anxious cleaning frenzy at Max’s house. They were the first friends of Max that I met and I could tell right away that they were amazing people.",
            "Overnight game nights at the couple’s house are always so much fun.",
            "You can see how much they love and cherish each other - both in their eyes and though their actions.",
          ],
        },
        {
          name: "Kathryn Anne Rucci Hayes",
          image: "kat",
          answers: [
            "To be honest, I have known Michael since I was a little kid as he used to hang out with some family friends of mine. It wasn’t until I was older and, through my boyfriend Logan, I met Moe and Michael. I first met them both at a dinner date with Logan. From then on we were fast growing friends.",
            "New Years Eve. Every year they hold an absolutely amazing party. Games and fun included. They create an atmosphere of comfort and family.",
            "That no matter what they are kind, loving, and always there for you. They also have a great sense of humor which is hard to find in this world.",
          ],
        },
      ],
      groomsmen: [
        {
          name: "Justin DeGraff",
          image: "justin",
          answers: [
            "I met Michael in high school during stage crew, and I met Morgan through Michael when she was 15.",
            "Literally an impossible choice.",
            "What I love most is that no matter how long we go without talking we are always able to pick up like family. as for what I love most about the two of them as a couple, it's that they balance one another. Somehow Morgan reigns in Michael’s chaos and Michael puts little charges of crazy into her order.",
          ],
          title: "Best Man",
        },
        {
          name: "Bryan Besser",
          image: "bryan",
          answers: [
            "I met Mike way back when in 2003 at a USY week long camp and he was my bunk-mate. We lost touch, then reconnected 2 years later at a USY convention where he shouted my name and gave me a bear hug. We've been brothers ever since.|I met Morgan my freshman year of college. It was before they started dating of course, but in walked Mike with Moe not far behind him. Many more fun times were to come, but there is no doubt Michael introduced me to Morgan, my pseudo little sister, long before all of this magic was even a twinkle.",
            "Oh god there are too many to count. We have been friends for too long for me to choose just one.",
            "Their immeasurable love for each other. I've seen both at their worst, and their absolute best. Rarely do you see 2 people absolutely meant for each other as Morgan and Mike. They might drive each other crazy, CONSTANTLY, and bicker like an old married couple, but the love they have for each other is immeasurable. They were meant to be together, and anyone who doubts that is a fool.",
          ],
        },
        {
          name: "Julian Varciag",
          image: "julian",
          answers: [
            "I met Mike working at Best Buy in June 2009. He then introduced me to Morgan about a month later.",
            "When they first became a couple, It was adorable. They had been best friends for years and super hesitant to risk losing their friendship. While Mike was confiding in me that he has feelings for Morgan but didn’t want lose her if she didn’t reciprocate, Morgan was confiding the exact same thing to my then wife. So, with some encouragement, and a promise everything will be fine, my wife and I left for a quick errand. When we came back, we saw Mike with his arm over Morgan’s shoulders, and the two biggest and goofiest smiles ever.",
            "They are among the few people in the world that I can count on. We’ve been through so much together, they’re family. To most people they are Mike and Morgan, to me they are Brotha and Sista.",
          ],
        },
        {
          name: "Max Litrofsky",
          image: "max",
          answers: [
            "I met Mike freshman year in high school, and through stage crew shenanigans! Later I met Morgan through Michael and other mutual friends of ours.",
            "Living with both Michael and Morgan is by far one of the most memorable things I have.",
            "They are some of the smartest, funniest, kindest, most resourceful and clever people I know. Between them, there's no challenge they can't take on together.",
          ],
        },
        {
          name: "Michael Sterenstein",
          image: "michael",
          answers: [
            "I met Michael in High School. I then met Morgan through Michael and our group of common friends.",
            "Introducing them to Korean BBQ and Chinese Hot Pot.",
            "They are both good friends of mine. They do check in on me when they haven’t heard from me for months. I also can count on them to grab a bite to eat.",
          ],
        },
      ],
    },
  }),
  methods: {
    getImageForMember(fileName: string) {
      // This is done this way to allow webpack to correctly
      // work its magic with the image files.
      // The string within `require` must be static so webpack can
      // resolve the path at compile time and correctly pack it with the
      // rest of the website's assets.

      switch (fileName) {
        case "bryan":
          return require("../assets/party-images/bryan.jpg");
        case "emily":
          return require("../assets/party-images/emily.jpg");
        case "heather":
          return require("../assets/party-images/heather.jpg");
        case "julian":
          return require("../assets/party-images/julian.jpg");
        case "justin":
          return require("../assets/party-images/justin.jpg");
        case "kat":
          return require("../assets/party-images/kat.jpg");
        case "max":
          return require("../assets/party-images/max.jpg");
        case "mckenna":
          return require("../assets/party-images/mckenna.jpg");
        case "michael":
          return require("../assets/party-images/michael.jpg");
        case "michalia":
          return require("../assets/party-images/michalia.jpg");
      }
    },
  },
});
